import { default as aboutUsHFeEw16jb4Meta } from "/app/pages/aboutUs.vue?macro=true";
import { default as checkQueuedEmails8KJrtrid4xMeta } from "/app/pages/admin/checkQueuedEmails.vue?macro=true";
import { default as editYViNz8UcS9Meta } from "/app/pages/admin/guest/[id]/edit.vue?macro=true";
import { default as indexWbBB2EL1TaMeta } from "/app/pages/admin/guest/[id]/index.vue?macro=true";
import { default as guests7HHLv7464JMeta } from "/app/pages/admin/guests.vue?macro=true";
import { default as editmzMztyO9tIMeta } from "/app/pages/admin/host/[id]/edit.vue?macro=true";
import { default as index2zMmZkb7byMeta } from "/app/pages/admin/host/[id]/index.vue?macro=true";
import { default as hostsES2q7ni4tWMeta } from "/app/pages/admin/hosts.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as inform4p0ytTaaVAMeta } from "/app/pages/admin/inform.vue?macro=true";
import { default as _91id_93yBVDO49y0FMeta } from "/app/pages/admin/match/[id].vue?macro=true";
import { default as indexmFXg3d5PyKMeta } from "/app/pages/admin/match/index.vue?macro=true";
import { default as _91id_93JgSLCuzcFaMeta } from "/app/pages/admin/matching/[id].vue?macro=true";
import { default as indexVYpuH5WLo2Meta } from "/app/pages/admin/matching/index.vue?macro=true";
import { default as matchyMHdYDj7ZMMeta } from "/app/pages/admin/matching/match.vue?macro=true";
import { default as migrateXpOvjGHxCdMeta } from "/app/pages/admin/migrate.vue?macro=true";
import { default as notAdminAccount9iW01cutCUMeta } from "/app/pages/admin/notAdminAccount.vue?macro=true";
import { default as notApprovedAdminPZVXysECQPMeta } from "/app/pages/admin/notApprovedAdmin.vue?macro=true";
import { default as queueEmailGuestsiUURPyD5TVMeta } from "/app/pages/admin/queueEmailGuests.vue?macro=true";
import { default as queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta } from "/app/pages/admin/queueEmailHostLastYearNotRegisteredAgain.vue?macro=true";
import { default as queueEmailMatchedGuestsSeZ5AFi054Meta } from "/app/pages/admin/queueEmailMatchedGuests.vue?macro=true";
import { default as queueEmailMatchedHostsfSglX2Ci4lMeta } from "/app/pages/admin/queueEmailMatchedHosts.vue?macro=true";
import { default as registerJ9n6lFFJhbMeta } from "/app/pages/admin/register.vue?macro=true";
import { default as resendMatchEmailsKd4CMkJ0Q0Meta } from "/app/pages/admin/resendMatchEmails.vue?macro=true";
import { default as roomsWz58ChAzt5Meta } from "/app/pages/admin/rooms.vue?macro=true";
import { default as confirmNewsletterTrqX2OYym4Meta } from "/app/pages/confirmNewsletter.vue?macro=true";
import { default as contactUssMQ6gX1U2qMeta } from "/app/pages/contactUs.vue?macro=true";
import { default as dsgvoze1kwAX27hMeta } from "/app/pages/dsgvo.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as HowItWorksIIHwyNaBEHMeta } from "/app/pages/HowItWorks.vue?macro=true";
import { default as impressumLDkFRkkSAmMeta } from "/app/pages/impressum.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as otp63Htgd7JyaMeta } from "/app/pages/otp.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as resendConfirmationEmailZkezVO4IOHMeta } from "/app/pages/resendConfirmationEmail.vue?macro=true";
import { default as confirmEmaildu4BEQuqJYMeta } from "/app/pages/signUp/guest/confirmEmail.vue?macro=true";
import { default as formbrygspJltbMeta } from "/app/pages/signUp/guest/form.vue?macro=true";
import { default as getStartedhEJkV56DFFMeta } from "/app/pages/signUp/guest/getStarted.vue?macro=true";
import { default as indexffJDdXbRL3Meta } from "/app/pages/signUp/guest/index.vue?macro=true";
import { default as successq1juMKpFngMeta } from "/app/pages/signUp/guest/success.vue?macro=true";
import { default as confirmEmailAJpaAgM21jMeta } from "/app/pages/signUp/host/confirmEmail.vue?macro=true";
import { default as formQJAOclckxYMeta } from "/app/pages/signUp/host/form.vue?macro=true";
import { default as getStarted4XwZtGx5S1Meta } from "/app/pages/signUp/host/getStarted.vue?macro=true";
import { default as index7CM3ILHpOJMeta } from "/app/pages/signUp/host/index.vue?macro=true";
import { default as success62Dy1UKI0RMeta } from "/app/pages/signUp/host/success.vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
export default [
  {
    name: "aboutUs___en",
    path: "/aboutUs",
    component: () => import("/app/pages/aboutUs.vue")
  },
  {
    name: "aboutUs___de",
    path: "/de/aboutUs",
    component: () => import("/app/pages/aboutUs.vue")
  },
  {
    name: "admin-checkQueuedEmails___en",
    path: "/admin/checkQueuedEmails",
    component: () => import("/app/pages/admin/checkQueuedEmails.vue")
  },
  {
    name: "admin-checkQueuedEmails___de",
    path: "/de/admin/checkQueuedEmails",
    component: () => import("/app/pages/admin/checkQueuedEmails.vue")
  },
  {
    name: "admin-guest-id-edit___en",
    path: "/admin/guest/:id()/edit",
    meta: editYViNz8UcS9Meta || {},
    component: () => import("/app/pages/admin/guest/[id]/edit.vue")
  },
  {
    name: "admin-guest-id-edit___de",
    path: "/de/admin/guest/:id()/edit",
    meta: editYViNz8UcS9Meta || {},
    component: () => import("/app/pages/admin/guest/[id]/edit.vue")
  },
  {
    name: "admin-guest-id___en",
    path: "/admin/guest/:id()",
    meta: indexWbBB2EL1TaMeta || {},
    component: () => import("/app/pages/admin/guest/[id]/index.vue")
  },
  {
    name: "admin-guest-id___de",
    path: "/de/admin/guest/:id()",
    meta: indexWbBB2EL1TaMeta || {},
    component: () => import("/app/pages/admin/guest/[id]/index.vue")
  },
  {
    name: "admin-guests___en",
    path: "/admin/guests",
    meta: guests7HHLv7464JMeta || {},
    component: () => import("/app/pages/admin/guests.vue")
  },
  {
    name: "admin-guests___de",
    path: "/de/admin/guests",
    meta: guests7HHLv7464JMeta || {},
    component: () => import("/app/pages/admin/guests.vue")
  },
  {
    name: "admin-host-id-edit___en",
    path: "/admin/host/:id()/edit",
    meta: editmzMztyO9tIMeta || {},
    component: () => import("/app/pages/admin/host/[id]/edit.vue")
  },
  {
    name: "admin-host-id-edit___de",
    path: "/de/admin/host/:id()/edit",
    meta: editmzMztyO9tIMeta || {},
    component: () => import("/app/pages/admin/host/[id]/edit.vue")
  },
  {
    name: "admin-host-id___en",
    path: "/admin/host/:id()",
    meta: index2zMmZkb7byMeta || {},
    component: () => import("/app/pages/admin/host/[id]/index.vue")
  },
  {
    name: "admin-host-id___de",
    path: "/de/admin/host/:id()",
    meta: index2zMmZkb7byMeta || {},
    component: () => import("/app/pages/admin/host/[id]/index.vue")
  },
  {
    name: "admin-hosts___en",
    path: "/admin/hosts",
    meta: hostsES2q7ni4tWMeta || {},
    component: () => import("/app/pages/admin/hosts.vue")
  },
  {
    name: "admin-hosts___de",
    path: "/de/admin/hosts",
    meta: hostsES2q7ni4tWMeta || {},
    component: () => import("/app/pages/admin/hosts.vue")
  },
  {
    name: "admin___en",
    path: "/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin___de",
    path: "/de/admin",
    meta: indexi8nCATZISJMeta || {},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-inform___en",
    path: "/admin/inform",
    meta: inform4p0ytTaaVAMeta || {},
    component: () => import("/app/pages/admin/inform.vue")
  },
  {
    name: "admin-inform___de",
    path: "/de/admin/inform",
    meta: inform4p0ytTaaVAMeta || {},
    component: () => import("/app/pages/admin/inform.vue")
  },
  {
    name: "admin-match-id___en",
    path: "/admin/match/:id()",
    meta: _91id_93yBVDO49y0FMeta || {},
    component: () => import("/app/pages/admin/match/[id].vue")
  },
  {
    name: "admin-match-id___de",
    path: "/de/admin/match/:id()",
    meta: _91id_93yBVDO49y0FMeta || {},
    component: () => import("/app/pages/admin/match/[id].vue")
  },
  {
    name: "admin-match___en",
    path: "/admin/match",
    meta: indexmFXg3d5PyKMeta || {},
    component: () => import("/app/pages/admin/match/index.vue")
  },
  {
    name: "admin-match___de",
    path: "/de/admin/match",
    meta: indexmFXg3d5PyKMeta || {},
    component: () => import("/app/pages/admin/match/index.vue")
  },
  {
    name: "admin-matching-id___en",
    path: "/admin/matching/:id()",
    meta: _91id_93JgSLCuzcFaMeta || {},
    component: () => import("/app/pages/admin/matching/[id].vue")
  },
  {
    name: "admin-matching-id___de",
    path: "/de/admin/matching/:id()",
    meta: _91id_93JgSLCuzcFaMeta || {},
    component: () => import("/app/pages/admin/matching/[id].vue")
  },
  {
    name: "admin-matching___en",
    path: "/admin/matching",
    meta: indexVYpuH5WLo2Meta || {},
    component: () => import("/app/pages/admin/matching/index.vue")
  },
  {
    name: "admin-matching___de",
    path: "/de/admin/matching",
    meta: indexVYpuH5WLo2Meta || {},
    component: () => import("/app/pages/admin/matching/index.vue")
  },
  {
    name: "admin-matching-match___en",
    path: "/admin/matching/match",
    meta: matchyMHdYDj7ZMMeta || {},
    component: () => import("/app/pages/admin/matching/match.vue")
  },
  {
    name: "admin-matching-match___de",
    path: "/de/admin/matching/match",
    meta: matchyMHdYDj7ZMMeta || {},
    component: () => import("/app/pages/admin/matching/match.vue")
  },
  {
    name: "admin-migrate___en",
    path: "/admin/migrate",
    component: () => import("/app/pages/admin/migrate.vue")
  },
  {
    name: "admin-migrate___de",
    path: "/de/admin/migrate",
    component: () => import("/app/pages/admin/migrate.vue")
  },
  {
    name: "admin-notAdminAccount___en",
    path: "/admin/notAdminAccount",
    component: () => import("/app/pages/admin/notAdminAccount.vue")
  },
  {
    name: "admin-notAdminAccount___de",
    path: "/de/admin/notAdminAccount",
    component: () => import("/app/pages/admin/notAdminAccount.vue")
  },
  {
    name: "admin-notApprovedAdmin___en",
    path: "/admin/notApprovedAdmin",
    component: () => import("/app/pages/admin/notApprovedAdmin.vue")
  },
  {
    name: "admin-notApprovedAdmin___de",
    path: "/de/admin/notApprovedAdmin",
    component: () => import("/app/pages/admin/notApprovedAdmin.vue")
  },
  {
    name: "admin-queueEmailGuests___en",
    path: "/admin/queueEmailGuests",
    meta: queueEmailGuestsiUURPyD5TVMeta || {},
    component: () => import("/app/pages/admin/queueEmailGuests.vue")
  },
  {
    name: "admin-queueEmailGuests___de",
    path: "/de/admin/queueEmailGuests",
    meta: queueEmailGuestsiUURPyD5TVMeta || {},
    component: () => import("/app/pages/admin/queueEmailGuests.vue")
  },
  {
    name: "admin-queueEmailHostLastYearNotRegisteredAgain___en",
    path: "/admin/queueEmailHostLastYearNotRegisteredAgain",
    meta: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta || {},
    component: () => import("/app/pages/admin/queueEmailHostLastYearNotRegisteredAgain.vue")
  },
  {
    name: "admin-queueEmailHostLastYearNotRegisteredAgain___de",
    path: "/de/admin/queueEmailHostLastYearNotRegisteredAgain",
    meta: queueEmailHostLastYearNotRegisteredAgainUgSU69LaV4Meta || {},
    component: () => import("/app/pages/admin/queueEmailHostLastYearNotRegisteredAgain.vue")
  },
  {
    name: "admin-queueEmailMatchedGuests___en",
    path: "/admin/queueEmailMatchedGuests",
    meta: queueEmailMatchedGuestsSeZ5AFi054Meta || {},
    component: () => import("/app/pages/admin/queueEmailMatchedGuests.vue")
  },
  {
    name: "admin-queueEmailMatchedGuests___de",
    path: "/de/admin/queueEmailMatchedGuests",
    meta: queueEmailMatchedGuestsSeZ5AFi054Meta || {},
    component: () => import("/app/pages/admin/queueEmailMatchedGuests.vue")
  },
  {
    name: "admin-queueEmailMatchedHosts___en",
    path: "/admin/queueEmailMatchedHosts",
    meta: queueEmailMatchedHostsfSglX2Ci4lMeta || {},
    component: () => import("/app/pages/admin/queueEmailMatchedHosts.vue")
  },
  {
    name: "admin-queueEmailMatchedHosts___de",
    path: "/de/admin/queueEmailMatchedHosts",
    meta: queueEmailMatchedHostsfSglX2Ci4lMeta || {},
    component: () => import("/app/pages/admin/queueEmailMatchedHosts.vue")
  },
  {
    name: "admin-register___en",
    path: "/admin/register",
    component: () => import("/app/pages/admin/register.vue")
  },
  {
    name: "admin-register___de",
    path: "/de/admin/register",
    component: () => import("/app/pages/admin/register.vue")
  },
  {
    name: "admin-resendMatchEmails___en",
    path: "/admin/resendMatchEmails",
    component: () => import("/app/pages/admin/resendMatchEmails.vue")
  },
  {
    name: "admin-resendMatchEmails___de",
    path: "/de/admin/resendMatchEmails",
    component: () => import("/app/pages/admin/resendMatchEmails.vue")
  },
  {
    name: "admin-rooms___en",
    path: "/admin/rooms",
    meta: roomsWz58ChAzt5Meta || {},
    component: () => import("/app/pages/admin/rooms.vue")
  },
  {
    name: "admin-rooms___de",
    path: "/de/admin/rooms",
    meta: roomsWz58ChAzt5Meta || {},
    component: () => import("/app/pages/admin/rooms.vue")
  },
  {
    name: "confirmNewsletter___en",
    path: "/confirmNewsletter",
    component: () => import("/app/pages/confirmNewsletter.vue")
  },
  {
    name: "confirmNewsletter___de",
    path: "/de/confirmNewsletter",
    component: () => import("/app/pages/confirmNewsletter.vue")
  },
  {
    name: "contactUs___en",
    path: "/contactUs",
    component: () => import("/app/pages/contactUs.vue")
  },
  {
    name: "contactUs___de",
    path: "/de/contactUs",
    component: () => import("/app/pages/contactUs.vue")
  },
  {
    name: "dsgvo___en",
    path: "/dsgvo",
    component: () => import("/app/pages/dsgvo.vue")
  },
  {
    name: "dsgvo___de",
    path: "/de/dsgvo",
    component: () => import("/app/pages/dsgvo.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "HowItWorks___en",
    path: "/HowItWorks",
    component: () => import("/app/pages/HowItWorks.vue")
  },
  {
    name: "HowItWorks___de",
    path: "/de/HowItWorks",
    component: () => import("/app/pages/HowItWorks.vue")
  },
  {
    name: "impressum___en",
    path: "/impressum",
    component: () => import("/app/pages/impressum.vue")
  },
  {
    name: "impressum___de",
    path: "/de/impressum",
    component: () => import("/app/pages/impressum.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "login___de",
    path: "/de/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "logout___en",
    path: "/logout",
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "logout___de",
    path: "/de/logout",
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "otp___en",
    path: "/otp",
    component: () => import("/app/pages/otp.vue")
  },
  {
    name: "otp___de",
    path: "/de/otp",
    component: () => import("/app/pages/otp.vue")
  },
  {
    name: "profile___en",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "profile___de",
    path: "/de/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "resendConfirmationEmail___en",
    path: "/resendConfirmationEmail",
    component: () => import("/app/pages/resendConfirmationEmail.vue")
  },
  {
    name: "resendConfirmationEmail___de",
    path: "/de/resendConfirmationEmail",
    component: () => import("/app/pages/resendConfirmationEmail.vue")
  },
  {
    name: "signUp-guest-confirmEmail___en",
    path: "/signUp/guest/confirmEmail",
    component: () => import("/app/pages/signUp/guest/confirmEmail.vue")
  },
  {
    name: "signUp-guest-confirmEmail___de",
    path: "/de/signUp/guest/confirmEmail",
    component: () => import("/app/pages/signUp/guest/confirmEmail.vue")
  },
  {
    name: "signUp-guest-form___en",
    path: "/signUp/guest/form",
    meta: formbrygspJltbMeta || {},
    component: () => import("/app/pages/signUp/guest/form.vue")
  },
  {
    name: "signUp-guest-form___de",
    path: "/de/signUp/guest/form",
    meta: formbrygspJltbMeta || {},
    component: () => import("/app/pages/signUp/guest/form.vue")
  },
  {
    name: "signUp-guest-getStarted___en",
    path: "/signUp/guest/getStarted",
    component: () => import("/app/pages/signUp/guest/getStarted.vue")
  },
  {
    name: "signUp-guest-getStarted___de",
    path: "/de/signUp/guest/getStarted",
    component: () => import("/app/pages/signUp/guest/getStarted.vue")
  },
  {
    name: "signUp-guest___en",
    path: "/signUp/guest",
    component: () => import("/app/pages/signUp/guest/index.vue")
  },
  {
    name: "signUp-guest___de",
    path: "/de/signUp/guest",
    component: () => import("/app/pages/signUp/guest/index.vue")
  },
  {
    name: "signUp-guest-success___en",
    path: "/signUp/guest/success",
    component: () => import("/app/pages/signUp/guest/success.vue")
  },
  {
    name: "signUp-guest-success___de",
    path: "/de/signUp/guest/success",
    component: () => import("/app/pages/signUp/guest/success.vue")
  },
  {
    name: "signUp-host-confirmEmail___en",
    path: "/signUp/host/confirmEmail",
    component: () => import("/app/pages/signUp/host/confirmEmail.vue")
  },
  {
    name: "signUp-host-confirmEmail___de",
    path: "/de/signUp/host/confirmEmail",
    component: () => import("/app/pages/signUp/host/confirmEmail.vue")
  },
  {
    name: "signUp-host-form___en",
    path: "/signUp/host/form",
    meta: formQJAOclckxYMeta || {},
    component: () => import("/app/pages/signUp/host/form.vue")
  },
  {
    name: "signUp-host-form___de",
    path: "/de/signUp/host/form",
    meta: formQJAOclckxYMeta || {},
    component: () => import("/app/pages/signUp/host/form.vue")
  },
  {
    name: "signUp-host-getStarted___en",
    path: "/signUp/host/getStarted",
    component: () => import("/app/pages/signUp/host/getStarted.vue")
  },
  {
    name: "signUp-host-getStarted___de",
    path: "/de/signUp/host/getStarted",
    component: () => import("/app/pages/signUp/host/getStarted.vue")
  },
  {
    name: "signUp-host___en",
    path: "/signUp/host",
    component: () => import("/app/pages/signUp/host/index.vue")
  },
  {
    name: "signUp-host___de",
    path: "/de/signUp/host",
    component: () => import("/app/pages/signUp/host/index.vue")
  },
  {
    name: "signUp-host-success___en",
    path: "/signUp/host/success",
    component: () => import("/app/pages/signUp/host/success.vue")
  },
  {
    name: "signUp-host-success___de",
    path: "/de/signUp/host/success",
    component: () => import("/app/pages/signUp/host/success.vue")
  },
  {
    name: "test___en",
    path: "/test",
    component: () => import("/app/pages/test.vue")
  },
  {
    name: "test___de",
    path: "/de/test",
    component: () => import("/app/pages/test.vue")
  }
]